@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/background';
@import '../../../theme/styles/scss/boxshadow';

.cart-button {
  position: sticky;
  bottom: toRem(102);
  left: calc(100% - toRem(68));
  z-index: 999;

  width: toRem(52);
  height: toRem(52);
  border-radius: 50%;
  border: 2px solid #fff;
  @include bg_linear(180deg, #ad5234, #8a2c15);
  @include box_shadow_button;

  & button {
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
  }

  &-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-text {
    position: absolute;
    top: toRem(-2);
    right: toRem(-3);

    width: toRem(20);
    height: toRem(20);
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-size: toRem(11);
    line-height: toRem(10);

    @include bg_linear(180deg);
  }
}
