@import '../../../theme/styles/scss/background';
@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/boxshadow';

.remain-button-list {
  display: flex;
  background: $color_dark;
  height: toRem(54);
}

.remain-button-item {
  width: 50%;
  max-width: 50%;
  flex: 1;
}
