@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/boxshadow';

.mode-bar {
  position: sticky;
  left: 0;
  bottom: toRem(42);
  z-index: 999;
  width: 100%;
  padding: 0 toRem(8);

  &-wrapper {
    display: flex;
    background-color: #fff;
    border-radius: toRem(23);
    padding: toRem(6) toRem(3);
    @include box_shadow_tabbar;
  }
}
