@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/boxshadow';

.history-button {
  position: sticky;
  left: calc(100% - toRem(68));
  bottom: toRem(172);
  z-index: 999;

  border: 2px solid #faa019;
  width: toRem(52);
  height: toRem(52);
  border-radius: 50%;
  background-color: #fff;
  @include box_shadow_button;

  & button {
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
