@import '.,/../../../../theme/styles/scss/function';

#category-thumbnail-block {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: toRem(128);

  .thumbnail {
    flex-basis: 50%;
    margin: 0;
    box-shadow: none;
    border: 0;
    padding: 0 toRem(10);
    opacity: 1;
    margin-bottom: toRem(20);

    &.disabled {
      opacity: 0.3;
    }

    img {
      object-fit: fill;
      width: 100%;
      background-color: #fff;
    }
  }
}
