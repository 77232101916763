@import './theme/styles/scss/function';
@import './theme/styles/scss/variables';

* {
  touch-action: manipulation;
}

body {
  #root {
    .modal {
      background: rgba(0, 0, 0, 0.5);
      touch-action: none;
      right: 0;
      bottom: 0;
    }

    &.member .modal {
      top: 44px;
      height: calc(100% - 44px);
    }
  }
  .non-member {
    top: 0px;
  }
  .member {
    top: 44px;
  }
}

.app-padding-bar {
  height: 44px;
  position: sticky;
  z-index: 2;
  top: 0;
  background: white;
}

.page-wrapper {
  background-color: $color_background;

  .layout-page {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &.cart {
    background-color: $color_light;
  }
}

.modal-backdrop {
  display: none;
}

/**** loading spinner ***/
.loading-app {
  height: 100vh;
  height: var(--app-height);
  background: $color_background;

  .spinner-border {
    width: toRem(32);
    height: toRem(32);
    color: $color_dark;
    border-width: toRem(2);
  }
}

/*  Ripple */
span.ripple {
  touch-action: none;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(242, 121, 0, 0.5);
  transform: scale(0);
  position: fixed;
  opacity: 1;
  z-index: 10000;
}

.rippleAnimation {
  animation: ripple 0.4s ease;
}

@keyframes ripple {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
