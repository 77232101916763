@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/variables';

.page-wrapper.cart,
.page-wrapper.history {
  height: -webkit-fill-available;
  z-index: 10;

  .layout-page {
    height: 100vh;
    height: -webkit-fill-available;
    height: var(--app-height);
  }
}

#cart {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#dishes {
  flex: 1;
  padding: toRem(16) toRem(20) 0 toRem(20);
  overflow: auto;

  .card {
    border: toRem(3) solid $text_white;
    border-radius: toRem(4);
  }

  .card img,
  .card .img-cover,
  .card .img-cover span {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-content {
    padding-top: toRem(5);
    padding-bottom: toRem(24);
    padding-left: toRem(10);
    padding-right: toRem(10);

    @media (min-width: 375px) {
      padding-left: toRem(14);
    }
  }

  .card .card-content h3 {
    font-size: toRem(14);
    line-height: toRem(20);
    font-style: normal;
    font-weight: 600;
    color: $color_contrast_text;
    margin-bottom: toRem(4);
  }

  .card .card-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: toRem(20);
    margin-bottom: toRem(4);
  }

  .card .card-content ul li {
    font-size: toRem(12);
    font-weight: 600;
  }

  .card .card-content ul li::before {
    content: '\A';
    width: toRem(4);
    height: toRem(4);
    border-radius: 50%;
    background: $color_contrast_text;
    display: inline-block;
    margin: toRem(2) toRem(5);
  }

  .dish-card {
    margin-bottom: toRem(16);
    display: flex;
    min-height: min-content;

    .dish-price {
      line-height: 1em;

      .price-root {
        font-size: toRem(12);
        line-height: 1em;

        @media (min-width: 360px) {
          font-size: toRem(14);
        }
      }

      .price-tax {
        font-size: toRem(10);
        line-height: 1em;

        @media (min-width: 360px) {
          font-size: toRem(12);
        }
      }
    }
  }

  .card .card-quantity {
    padding-bottom: toRem(5);

    .text-quantity {
      color: $text_secondary;
      font-size: toRem(11);
      line-height: toRem(16);
      margin-left: toRem(10);
      margin-right: toRem(8);

      @media (min-width: 375px) {
        margin-left: toRem(12);
      }
    }
  }
}

.cart-footer {
  margin-top: auto;
  -webkit-overflow-scrolling: touch !important;
  touch-action: none;
  background-color: $text_white;
  border-top: toRem(2) solid $color_background;
  padding: toRem(14) toRem(16) toRem(34) toRem(16);

  -webkit-border-top-left-radius: 0rem;
  -webkit-border-top-right-radius: 0rem;
  -moz-border-radius-topleft: 0rem;
  -moz-border-radius-topright: 0rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;

  .cart-price {
    margin-bottom: toRem(18);
  }

  .total-price {
    align-items: center;
    text-align: right;
    padding-right: toRem(8);

    .dish-price {
      .price-root {
        font-size: toRem(19);

        @media (min-width: 360px) {
          font-size: toRem(22);
        }

        @media (min-width: 375px) {
          font-size: toRem(24);
        }
      }

      .price-root-value {
        color: $color_dark;
      }

      .price-tax {
        font-size: toRem(12);

        @media (min-width: 360px) {
          font-size: toRem(14);
        }
      }
    }
  }

  .total-price-text {
    font-size: toRem(14);
    font-style: normal;
    font-weight: 600;
    line-height: toRem(20);
    padding-left: toRem(4);
    display: flex;
    align-items: center;
  }

  .button-cart-back {
    padding-right: toRem(8);
    button {
      width: 100%;
      font-weight: 700;
      font-size: toRem(16);
      line-height: toRem(24);
    }
  }
  .button-cart-order {
    padding-left: toRem(8);
    button {
      width: 100%;
      font-weight: 700;
      font-size: toRem(16);
      line-height: toRem(24);
    }
  }
}
