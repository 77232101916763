.page-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  will-change: transform, z-index;
}

.page-effect-enter {
  // opacity: 0;
  transform: translate3d(100%, 0, 0);
  z-index: 9998;
}

.page-effect-enter.page-effect-enter-active {
  // opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 500ms ease;
  // transition: opacity 250ms ease-out, transform 500ms ease;
}

.page-effect-exit {
  // opacity: 1;
  transform: translate3d(0, 0, 0);
  z-index: 9999;
  overflow: hidden;
}

.page-effect-exit.page-effect-exit-active {
  // opacity: 0;
  transform: translate3d(100%, 0, 0);
  transition: transform 500ms ease;
  // transition: opacity 250ms ease-out, transform 500ms ease;
}
