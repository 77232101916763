@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/boxshadow';

.dish-modal {
  z-index: 1051 !important;
  top: var(--position-modal-top) !important;

  .modal-dialog {
    padding-top: toRem(54);
  }

  .modal-btn-close-wrapper {
    position: absolute;
    top: toRem(4);
    right: toRem(0);
    z-index: 600;
    padding: toRem(16);
  }

  .modal-btn-close {
    z-index: 600;
    width: toRem(30);
    height: toRem(30);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    appearance: none;
    outline: none;
    background-color: $color_dark;
    @include box_shadow_button;
  }

  .dish-name {
    padding: toRem(26) toRem(56) toRem(26) toRem(16);
    font-weight: 700;
    font-size: toRem(20);
    line-height: toRem(32);
  }

  .dish-description {
    padding: 0 toRem(24);
    font-size: toRem(14);
    line-height: toRem(24);
    margin-bottom: toRem(16);
  }

  .collapse-button {
    color: white;
    display: flex !important;
    text-align: left;
    background-color: $color_dark;
    padding: toRem(10) toRem(8) toRem(10) toRem(16);

    .order-name {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      font-size: toRem(14);
      line-height: toRem(24);
    }

    &.collapse .order-name-nav {
      transform: rotate(180deg);
    }

    button.delete-order {
      border: none;
      border-radius: 1vw;
      background-color: white;
      color: #ffa500;
    }
  }

  .delete-list-option-group div {
    padding-top: toRem(4);
    padding-bottom: toRem(8);
  }

  .selected-options {
    padding-top: toRem(15);
  }

  .selected-option-item {
    font-size: toRem(14);
    line-height: toRem(24);
  }

  .list-group-wrapper {
    padding: 0 toRem(16) toRem(24);
  }

  .option-group-header {
    padding: toRem(14) toRem(8);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  .list-option-group {
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: toRem(8);
    overflow: hidden;
    transform: translateZ(0);
  }

  li.menu-option {
    border: none;
    background-color: $color_background;
    padding: toRem(10) toRem(12);
    margin-bottom: 1px;

    .ara-checkbox,
    .ara-radio {
      font-size: toRem(14);
      line-height: toRem(18);
      display: inline-flex;
      align-items: center;
      padding-bottom: toRem(3);
    }

    .option-price {
      text-align: right;
      color: $color_main;
      font-weight: 600;
      font-size: toRem(12);
      line-height: toRem(20);

      .tax-included {
        color: $text_secondary;
        display: block;
      }

      &.free {
        color: black;
      }

      &.kousenum {
        .tax-included {
          display: inline;
        }
      }
    }
  }

  .dish-price-section {
    padding: 0 toRem(24);
  }

  .dish-footer {
    border-top: 1px solid rgba(217, 215, 210, 0.5);
    background-color: white;
    padding: toRem(20) toRem(16) toRem(24);
    touch-action: none;

    @media only screen and (min-width: 360px) {
      padding-left: toRem(24);
      padding-right: toRem(24);

      .btn-group.quantity-control span {
        padding: 0 toRem(16);
      }
    }
  }

  .add-cart-btn {
    min-width: toRem(240);
  }

  .dish-footer-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: toRem(12);

    .price-root-value {
      color: $color_dark;
    }

    .price-tax {
      font-size: toRem(12);

      @media (min-width: 375px) {
        font-size: toRem(14);
      }
    }
  }
}

.dish-modal-confirm {
  top: var(--position-modal-top);
}
