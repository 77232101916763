@use 'sass:math';

@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/function';

$size: math.div(1, 2);

.genre-name {
  background-color: $color_dark;
  color: white;
  text-align: center;
  padding: toRem(4) toRem(6) toRem(8);
  font-weight: bold;
  width: 100%;
  position: relative;
  z-index: 1000;
  transition: all ease 0.1s;

  &.hide {
    padding: 0;
    opacity: 0;
    height: 0;
  }

  &.sticky {
    position: sticky;
    top: toRem(88);
    z-index: 999;
  }

  #smart-order.member &.sticky {
    top: toRem(132);
  }

  .page-wrapper.all-you-can-eat &.sticky {
    top: toRem(142);
  }

  #smart-order.member .page-wrapper.all-you-can-eat &.sticky {
    top: toRem(186);
  }
}

.menu-list {
  background-color: $color_background;
}

.menu-list-item {
  background-color: $color_background;
}

.menu-list .thumbnail-block {
  display: flex;
  flex-wrap: wrap;

  padding: toRem(6) 2.6vw;

  &.no-result {
    padding: 1rem 0.5rem;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
  }

  .menu-detail-thumbnail {
    position: relative;
    flex-basis: $size * 100%;
    width: $size * 100%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 2.6vw;
    box-shadow: none;
    border: 0;

    &.disabled {
      opacity: 0.35;
    }

    &.call-staff .thumbnail {
      opacity: 0.35;
    }

    .thumbnail {
      height: 100%;
      .card-body {
        background-color: #ffdc96;
        -webkit-border-bottom-right-radius: 3vw;
        -webkit-border-bottom-left-radius: 3vw;
        -moz-border-radius-bottomright: 3vw;
        -moz-border-radius-bottomleft: 3vw;
        border-bottom-right-radius: 3vw;
        border-bottom-left-radius: 3vw;
      }
      .card-price {
        line-height: toRem(24);

        .price-value {
          font-size: toRem(11);
          font-weight: 900;
          color: #000;
        }

        .price-root {
          font-size: toRem(10);
          font-weight: 700;
          color: $color_contrast_text;
          margin-right: 4px;
        }

        .price-tax {
          font-size: toRem(10);
          font-weight: 600;
          color: $text_secondary;
        }

        @media screen and (min-width: 360px) {
          .price-root,
          .price-tax {
            font-size: toRem(12);
          }

          .price-value {
            font-size: toRem(14);
          }
        }
      }

      img {
        object-fit: fill;
        // height: $size * 61vw; // set value specifically to make image loading more consistent
        width: 100%;
        height: 26vw;
      }
    }
    div.fav-icon-wrapper {
      position: absolute;
      top: 10%;
      right: 8%;
      padding: 0.5rem;
      opacity: 0.5;
      border-radius: 50%;
      background-color: grey;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: red;
      }
      i.bi {
        color: white;
        line-height: 1rem;
      }
    }
    .message {
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 3.4vw;
      font-size: 0.75rem;
      line-height: 1.25em;
      width: 100%;
      height: 26vw;
      padding-left: 2.6vw;
      padding-right: 2.6vw;

      .message-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.genre-menu-block:last-child {
  padding-bottom: toRem(128);
}
