@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/boxshadow';

#category-button-block {
  position: sticky;
  top: toRem(44);
  left: 0;
  z-index: 999;
  padding: toRem(5) toRem(4);
  background-color: #fff;

  @include box_shadow_tabbar;

  .header-wrapper.all-you-can-eat ~ .main-content & {
    top: toRem(98);
  }

  #smart-order.member & {
    top: toRem(88);
  }

  #smart-order.member .header-wrapper.all-you-can-eat ~ .main-content & {
    top: toRem(142);
  }
}

.category-button {
  font-size: toRem(15);
  letter-spacing: 0.95pt;
  line-height: toRem(24);
  font-weight: 600;
  white-space: nowrap;
  padding: toRem(4) toRem(13) toRem(6);
  margin: 0 toRem(4);
  border-radius: toRem(17);
  background-color: #fff;
  color: $color_dark;
  text-align: center;
  cursor: pointer;

  &.active {
    background-color: $color_light;
    color: $color_contrast_text;
  }

  #category-button-block.search & {
    background-color: #fff;
    color: $color_dark;
  }
}
